// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./fonts/1898Sans-Regular.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./fonts/1898Sans-Bold.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./images/globe-trimmed.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./images/background-gradient.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "@font-face {\n  font-family: '1898 Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('opentype');\n  font-weight: normal;\n}\n@font-face {\n  font-family: '1898 Sans';\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('opentype');\n  font-weight: bold;\n}\n\n.world-quant-body h1, .world-quant-sample-body h1 {\n  font-family: '1898 Sans', sans-serif;\n  font-size: 34px;\n  line-height: 45px;\n}\n.world-quant-body h2, .world-quant-sample-body h2 {\n  font-family: '1898 Sans', sans-serif;\n  font-size: 28px;\n  line-height: 42px;\n}\n\n.worldquant-logo {\n  width: 504px;\n  max-width: 100%;\n}\n\n.top-header, .bottom-header, .worldquant-footer {\n  color: #FFFFFF;\n  font-weight: bold;\n}\n\n.top-header {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + "), url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n  background-size: auto 100%, cover;\n  background-position: center center, top center;\n  background-repeat: no-repeat, no-repeat;\n}\n\n.top-header p {\n  font-size: 42px;\n  line-height: 50px;\n}\n\n.bottom-header {\n  padding: 75px 0;\n}\n\n.bottom-header p {\n  margin: 25px 0;\n  font-size: 28px;\n  line-height: 42px;\n}\n\n.worldquant-footer {\n  margin-top: 2em;\n  background-color: #000000;\n}\n\n.footer-wrapper {\n  padding-top: 75px;\n  padding-bottom: 75px;\n}\n\n.worldquant-footer p {\n  position: absolute;\n  bottom: 0;\n  right: 0;\n  margin: 0;\n  text-align: right;\n  text-transform: uppercase;\n}\n\n@media screen and (max-width: 320px) {\n  .top-header img {\n    margin: 20px 0;\n  }\n  .top-header p {\n    margin-bottom: 20px;\n    font-size: 1.5em;\n  }\n  .footer-wrapper {\n    padding: 12px 15px 5px 15px;\n  }\n}\n", ""]);
// Exports
module.exports = exports;
