const taxa_suggestions = [
  'Lactobacillus acidipiscis',
  'Acinetobacter johnsonii',
  'Klebsiella quasipneumoniae',
  'Comamonas testosteroni',
  'Sodalis glossinidius',
  'Deinococcus gobiensis',
  'Desulfuromonas soudanensis',
  'Bdellovibrio bacteriovorus',
  'Human betaherpesvirus 5',
  'Polynucleobacter duraquae',
  'Enterococcus casseliflavus',
  'Lactobacillus paraplantarum',
  'Nocardiopsis dassonvillei',
  'Delftia acidovorans',
  'Escherichia virus 4MG',
  'Shewanella sediminis',
  'Sphaerobacter thermophilus',
  'Polaromonas naphthalenivorans',
  'Martelella mediterranea',
  'Achromobacter insolitus',
  '[Eubacterium] sulci',
  'Listeria welshimeri',
  'Kangiella geojedonensis',
  'Mycobacterium kansasii',
  'Pantoea vagans',
  'Bacillus altitudinis',
  'Staphylococcus haemolyticus',
  'Terriglobus roseus',
  'Atopobium parvulum',
  'Spirosoma radiotolerans',
  'Dietzia timorensis',
  'Lactobacillus coryniformis',
  'Staphylococcus nepalensis',
  'Sphingorhabdus flavimaris',
  'Streptococcus sanguinis',
  'Calothrix parietina',
  'Candidatus Thiodictyon syntrophicum',
  'Mycobacterium indicus pranii',
  '[Bacillus] selenitireducens',
  'Novibacillus thermophilus',
  'Pseudarthrobacter chlorophenolicus',
  'Bacillus methanolicus',
  'Photorhabdus luminescens',
  'Weissella ceti',
  'Anaerolineaceae bacterium oral taxon 439',
  'Parageobacillus thermoglucosidasius',
  'Lentzea guizhouensis',
  'Vibrio nigripulchritudo',
  'Bordetella holmesii',
  'Aromatoleum aromaticum',
  'Salinispora tropica',
  'Lentibacillus amyloliquefaciens',
  'Bradyrhizobium oligotrophicum',
  'Vagococcus penaei',
  'Sodalis phage phiSG1',
  'Pseudomonas koreensis',
  'Azospira oryzae',
  'Starkeya novella',
  'Propionibacterium phage PHL141',
  'Pedobacter ginsengisoli',
  'Corynebacterium vitaeruminis',
  'Rubinisphaera brasiliensis',
  'Stenotrophomonas acidaminiphila',
  'Neisseria meningitidis',
  'Vibrio cholerae',
  'Paenibacillus odorifer',
  'Rhodovulum sulfidophilum',
  'Enterobacteria phage phiP27',
  'Fusobacterium periodonticum',
  'Burkholderia gladioli',
  'Bacillus velezensis',
  'Croceicoccus marinus',
  'Alkaliphilus oremlandii',
  'Megamonas hypermegale',
  'Clostridium phage phiCT453A',
  'Saccharothrix espanaensis',
  'Hyphomicrobium nitrativorans',
  'Propionibacterium virus P1.1',
  'Phoenicibacter massiliensis',
  'Actinomyces gaoshouyii',
  'Molluscum contagiosum virus',
  'Haloferax mediterranei',
  'Synechococcus phage ACG-2014e',
  'Geobacillus thermocatenulatus',
  'Staphylococcus phage IME-SA4',
  'Citrobacter farmeri',
  'Corynebacterium simulans',
  'Herbaspirillum seropedicae',
  'Bibersteinia trehalosi',
  'Leclercia adecarboxylata',
  'Acetobacter senegalensis',
  'Pseudomonas mandelii',
  'Thermosynechococcus elongatus',
  'Pseudarthrobacter phenanthrenivorans',
  'Neisseria weaveri',
  'Verrucosispora maris',
  'Raoultella planticola',
  'Proteiniphilum saccharofermentans',
  'Gloeobacter kilaueensis',
  'Mycobacterium chelonae',
  'Brevundimonas vesicularis',
  'Truepera radiovictrix',
  'Ralstonia insidiosa',
  'Bifidobacterium thermophilum',
  'Erysipelothrix rhusiopathiae',
  'Phaeobacter gallaeciensis',
  'Paenibacillus kribbensis',
  'Deinococcus deserti',
  'Idiomarina piscisalsi',
  'Salinicoccus halodurans',
  'Zunongwangia profunda',
  'Aeromonas dhakensis',
  'Edwardsiella piscicida',
  'Halalkalicoccus jeotgali',
  'Pseudoalteromonas agarivorans',
  'Paenibacillus mucilaginosus',
  'Akkermansia muciniphila',
  'Fictibacillus arsenicus',
  'Cronobacter condimenti',
  'Aeromonas hydrophila',
  'Desulfomonile tiedjei',
  'Bacteriovorax stolpii',
  'Salmonella bongori',
  'Selenomonas ruminantium',
  'Thioalkalivibrio sulfidiphilus',
  'Corynebacterium sphenisci',
  'Mycobacterium sinense',
  'Gammapapillomavirus 1',
  'Halogeometricum borinquense',
  'Bacillus sonorensis',
  'Anaerotignum propionicum',
  'Nocardiopsis alba',
  'Pannonibacter phragmitetus',
  'Klebsiella virus F19',
  'Brevibacillus brevis',
  'Marinovum algicola',
  'Pseudomonas corrugata',
  'Vulgatibacter incomptus',
  'Betapapillomavirus 3',
  'Altererythrobacter dongtanensis',
  'Mycobacterium bovis',
  'Trueperella pyogenes',
  'Frischella perrara',
  'Streptomyces xiamenensis',
  'Staphylococcus stepanovicii',
  'Sulfitobacter pseudonitzschiae',
  'Desulfovibrio africanus',
  'Citrobacter rodentium',
  'Kocuria palustris',
  'Thioalkalivibrio versutus',
  'Prevotella fusca',
  'Staphylococcus virus IPLA5',
  'Bifidobacterium catenulatum',
  'Methanosphaera stadtmanae',
  'Sphingomonas sanxanigenens',
  'Propionibacterium virus P144',
  'Pseudoalteromonas aliena',
  'Vibrio furnissii',
  'Lactobacillus rhamnosus',
  'Spiribacter salinus',
  'Opitutaceae bacterium TAV5',
  'Arenibacter algicola',
  'Magnetospirillum magneticum',
  'Streptomyces violaceusniger',
  'Lactobacillus gallinarum',
  'Enterobacter hormaechei',
  'Clostridium tyrobutyricum',
  'Virgibacillus halodenitrificans',
  'Bacteroides salanitronis',
  'Methylobacterium aquaticum',
  'Enterococcus mundtii',
  'Herbaspirillum rubrisubalbicans',
  'Propionibacterium virus P101A',
  'Flavobacterium psychrophilum',
  'Serratia fonticola',
  'Propionibacterium virus Wizzo',
  'Algibacter alginicilyticus',
  'Flavobacterium columnare',
  'Lactococcus lactis',
  'Aeromonas veronii',
  'Comamonas serinivorans',
  'Lachnoclostridium phytofermentans',
  'Ruegeria mobilis',
  'Thermus scotoductus',
  'Streptomyces parvulus',
  'Paracoccus zhejiangensis',
  'Burkholderia pyrrocinia',
  'Acidithiobacillus caldus',
  'Brachyspira pilosicoli',
  'Streptococcus infantarius',
  'Buchnera aphidicola',
  'Enterobacteriaceae bacterium ENNIH1',
  'Maribacter cobaltidurans',
  'Geobacter bemidjiensis',
  'Shewanella frigidimarina',
  'Propionibacterium virus P1001',
  'Canarypox virus',
  'Alistipes finegoldii',
  'Lactobacillus kefiranofaciens',
  'Pseudomonas mosselii',
  'Vibrio tasmaniensis',
  'Vibrio shilonii',
  'Gordonia phage Phinally',
  'Candidatus Hamiltonella defensa',
  'Bacillus smithii',
  'Bacillus xiamenensis',
  'Streptococcus intermedius',
  'Streptococcus salivarius',
  'Staphylococcus carnosus',
  'Sphingomonas panacis',
  'Methylobacterium extorquens',
  'Corynebacterium cystitidis',
  'Desulfotomaculum ferrireducens',
  'Phaeobacter piscinae',
  'Agromyces aureus',
  'Thermus parvatiensis',
  'Agrobacterium fabrum',
  'Oligotropha carboxidovorans',
  'Cronobacter universalis',
  'Croceibacter atlanticus',
  'Corynebacterium glutamicum',
  'Mucilaginibacter gotjawali',
  'Actinopolyspora erythraea',
  'Thermobaculum terrenum',
  'Antarctobacter heliothermus',
  'Obesumbacterium proteus',
  'Anaerococcus mediterraneensis',
  'Runella slithyformis',
  'Corynebacterium doosanense',
  'Thauera chlorobenzoica',
  'Burkholderia cenocepacia',
  'Prevotella enoeca',
  'Cupriavidus necator',
  'Candidatus Promineofilum breve',
  'Mycobacterium paraintracellulare',
  'Haemophilus parainfluenzae',
  'Pedobacter heparinus',
  'Xylanimonas cellulosilytica',
  "endosymbiont 'TC1' of Trimyema compressum",
  'Lysinibacillus sphaericus',
  'Carnobacterium maltaromaticum',
  'Myxococcus hansupus',
  'Streptococcus oralis',
  'Tetragenococcus halophilus',
  'Olsenella uli',
  'Pandoraea oxalativorans',
  'Chrysochromulina ericina virus',
  'Corynebacterium pseudotuberculosis',
  'Acidovorax avenae',
  'Moorea producens',
  'Acinetobacter phage vB AbaM Acibel004',
  'Yersinia enterocolitica',
  'Saccharomyces cerevisiae',
  'Desulfovibrio vulgaris',
  'Oenococcus oeni',
  'Corynebacterium mustelae',
  'Maricaulis maris',
  'Moritella viscosa',
  'Desulfitobacterium dehalogenans',
  'Klebsiella variicola',
  'Burkholderia glumae',
  'Macrococcus canis',
  'Tsukamurella virus TIN2',
  'Gordonia polyisoprenivorans',
  'Lysobacter gummosus',
  'beta proteobacterium CB',
  'Pseudomonas parafulva',
  'Bacillus amyloliquefaciens',
  'Microbacterium chocolatum',
  'Haliangium ochraceum',
  'Curtobacterium pusillum',
  'Enterobacter ludwigii',
  'Human polyomavirus 10',
  'Streptococcus mitis',
  'Natrialba magadii',
  'Gramella forsetii',
  'Achromobacter phage phiAxp-3',
  'Pseudomonas versuta',
  'Erythrobacter seohaensis',
  'Rickettsia bellii',
  'Burkholderia pseudomallei',
  'Pandoraea pulmonicola',
  'Streptococcus pasteurianus',
  'Rhodomicrobium vannielii',
  'Gibbsiella quercinecans',
  'Mycobacterium intracellulare',
  'Arthrobacter crystallopoietes',
  'Erythrobacter flavus',
  'Propionibacterium virus Solid',
  'Klebsiella oxytoca',
  'Methanosarcina barkeri',
  'Clostridium baratii',
  'Staphylococcus virus PH15',
  'Zobellella denitrificans',
  'Chryseobacterium piperi',
  'Candidatus Nitrospira inopinata',
  'Deinococcus maricopensis',
  'Corynebacterium aquilae',
  'Streptomyces lividans',
  'Bradyrhizobium icense',
  'Modestobacter marinus',
  'Capnocytophaga gingivalis',
  'Denitrobacterium detoxificans',
  'Coraliomargarita akajimensis',
  'Celeribacter manganoxidans',
  'Escherichia albertii',
  'Paenibacillus yonginensis',
  'Agarivorans gilvus',
  'Bartonella apis',
  'Porphyrobacter neustonensis',
  'Mycobacterium marseillense',
  'Klebsiella virus SU503',
  'Spiroplasma kunkelii',
  'Brachybacterium faecium',
  'Burkholderia ubonensis',
  'Solitalea canadensis',
  'Halobiforma lacisalsi',
  'Pseudarthrobacter sulfonivorans',
  'Paraburkholderia phymatum',
  'Rubrobacter xylanophilus',
  'Chlorobaculum tepidum',
  'Pseudomonas silesiensis',
  'Nitrospira moscoviensis',
  'Rhizobium tropici',
  'Kitasatospora albolonga',
  'Bifidobacterium animalis',
  'Geobacter uraniireducens',
  'Leuconostoc carnosum',
  'Mycobacterium aurum',
  'Bordetella pertussis',
  'Pantoea stewartii',
  'Deinococcus puniceus',
  "Enterobacter cloacae complex 'Hoffmann cluster IV'",
  'Rahnella aquatilis',
  'Roseibacterium elongatum',
  'Enterococcus phage EF62phi',
  'Serratia liquefaciens',
  'Cronobacter turicensis',
  'Serratia plymuthica',
  'Lactobacillus buchneri',
  'Streptococcus pyogenes',
  'Desulfosporosinus meridiei',
  'Prevotella ruminicola',
  'Pseudomonas phage Phi-S1',
  'Granulicella mallensis',
  'Micromonospora aurantiaca',
  'Propionibacterium phage PHL041',
  'Rhodoferax koreense',
  'Brachybacterium ginsengisoli',
  'Burkholderia ambifaria',
  'Stenotrophomonas rhizophila',
  'Xanthomonas albilineans',
  'Corynebacterium efficiens',
  'Coxiella-like endosymbiont',
  'Pediococcus acidilactici',
  'Paenibacillus durus',
  'Marichromatium purpuratum',
  'Bacterioplanes sanyensis',
  'Listeria monocytogenes',
  'Streptomyces avermitilis',
  'Pandoraea thiooxydans',
  'Lactococcus virus c2',
  'Paracoccus aminovorans',
  'Corynebacterium terpenotabidum',
  'Streptococcus macedonicus',
  'Pseudodesulfovibrio indicus',
  'Rhodopseudomonas palustris',
  'Tateyamaria omphalii',
  'Streptomyces lincolnensis',
  'Simiduia agarivorans',
  'Tenacibaculum jejuense',
  'Salinibacter ruber',
  'Moraxella ovis',
  'Acidovorax citrulli',
  'Pseudoalteromonas haloplanktis',
  'Nitrosomonas ureae',
  'Porcine associated gemycircularvirus 1',
  'Geobacter lovleyi',
  'Halostagnicola larsenii',
  'Leptotrichia buccalis',
  'Taylorella equigenitalis',
  'Campylobacter helveticus',
  'Candidatus Paracaedibacter acanthamoebae',
  'Amycolatopsis orientalis',
  'Spirosoma montaniterrae',
  'Spiroplasma cantharicola',
  'Paraburkholderia hospita',
  'Thiocystis violascens',
  'Kribbella flavida',
  'Arthrobacter alpinus',
  'Bacillus anthracis',
  'Paenibacillus borealis',
  'Kocuria rhizophila',
  'Microbulbifer aggregans',
  'Bacillus phage Mater',
  'Alkalilimnicola ehrlichii',
  'Deinococcus proteolyticus',
  'Mycobacterium fortuitum',
  'Fervidobacterium pennivorans',
  'Ruminococcus bicirculans',
  'Acinetobacter schindleri',
  'Lactobacillus koreensis',
  'Klebsiella virus PKP126',
  'Thiohalobacter thiocyanaticus',
  'Mycobacterium avium',
  'Prauserella marina',
  'Corynebacterium kroppenstedtii',
  'Staphylococcus agnetis',
  'Listeria seeligeri',
  'Pseudomonas plecoglossicida',
  'Actinomyces meyeri',
  'Lactobacillus hokkaidonensis',
  'Microlunatus phosphovorus',
  'Pandoraea pnomenusa',
  'Rhizobium phaseoli',
  'Sphaerochaeta pleomorpha',
  'Paenibacillus naphthalenovorans',
  'Anaerostipes hadrus',
  'Desulfarculus baarsii',
  'Bacteroides caccae',
  'Streptococcus acidominimus',
  'Achromobacter spanius',
  'Pseudoalteromonas spongiae',
  'Desulfomicrobium orale',
  'Brenneria goodwinii',
  'Pseudomonas alcaliphila',
  'Xanthomonas oryzae',
  'Gluconacetobacter diazotrophicus',
  'Brochothrix phage NF5',
  'Xanthomonas vesicatoria',
  'Nitratireductor basaltis',
  'Streptococcus cristatus',
  'Bacillus thuringiensis',
  'Shigella dysenteriae',
  'Propionibacterium virus PA6',
  'Cyclobacterium amurskyense',
  'Invertebrate iridescent virus 6',
  'Streptomyces leeuwenhoekii',
  'Corynebacterium atypicum',
  'Leptothrix cholodnii',
  'Lactobacillus curieae',
  'Streptomyces cattleya',
  'Murine mastadenovirus B',
  'Methanobrevibacter olleyae',
  'Phaeobacter inhibens',
  'Altererythrobacter ishigakiensis',
  'Symbiobacterium thermophilum',
  'Microterricola viridarii',
  'Human mastadenovirus C',
  'Stackebrandtia nassauensis',
  'Corynebacterium jeikeium',
  'Pseudomonas phage phiPsa374',
  'Bacillus beveridgei',
  'Nocardia farcinica',
  'Paenibacillus graminis',
  'Gluconobacter albidus',
  'Mycobacterium phlei',
  'Thermothelomyces thermophila',
  'Paenibacillus larvae',
  'Dermabacter vaginalis',
  'Corynebacterium flavescens',
  'Carnobacterium inhibens',
  'Pelobacter propionicus',
  'Segniliparus rotundus',
  'Agrobacterium tumefaciens',
  'Anaerococcus prevotii',
  'secondary endosymbiont of Ctenarytaina eucalypti',
  'Clostridium tetani',
  'Erwinia phage vB EamM Huxley',
  'Staphylococcus virus IPLAC1C',
  'Azorhizobium caulinodans',
  'Methylobacterium nodulans',
  'Methanobacterium formicicum',
  'Yersinia pseudotuberculosis',
  'Paraburkholderia rhizoxinica',
  'Zhongshania aliphaticivorans',
  'Achromobacter xylosoxidans',
  'Corynebacterium urealyticum',
  'Lawsonia intracellularis',
  'Granulicella tundricola',
  'Thermus thermophilus',
  'Kangiella sediminilitoris',
  'Alphapapillomavirus 2',
  'Mycobacterium vanbaalenii',
  'Paenarthrobacter aurescens',
  'Pandoraea sputorum',
  'Cronobacter muytjensii',
  'Clostridium chauvoei',
  'Mycobacterium shigaense',
  'Corynebacterium phage P1201',
  'Kluyveromyces marxianus',
  'Propionibacterium virus PHL112N00',
  'Enterococcus wangshanyuanii',
  'Cutibacterium avidum',
  'Bacillus bombysepticus',
  'Parascardovia denticolens',
  'Pontibacter akesuensis',
  'Leptolyngbya boryana',
  'Myroides odoratimimus',
  '[Clostridium] cellulosi',
  'Pragia fontium',
  'Streptomyces clavuligerus',
  'Pseudomonas aeruginosa',
  'Cryptobacterium curtum',
  'Streptococcus gallolyticus',
  'Flammeovirgaceae bacterium 311',
  'Methylobacillus flagellatus',
  'Halobacteriovorax marinus',
  'Enterococcus faecalis',
  'Shewanella bicestrii',
  'Hymenobacter swuensis',
  'Leuconostoc kimchii',
  'Mycobacterium litorale',
  'Bifidobacterium scardovii',
  'Edwardsiella hoshinae',
  'Lactobacillus sakei',
  'Sinorhizobium meliloti',
  'Kosakonia sacchari',
  'Halorhodospira halophila',
  'Rhodococcus qingshengii',
  'Candidatus Filomicrobium marinum',
  'Dyadobacter fermentans',
  'Ruegeria pomeroyi',
  'Paludibacter propionicigenes',
  'Mesorhizobium amorphae',
  'Acetobacter aceti',
  'Paenibacillus polymyxa',
  'Thielavia terrestris',
  'Amycolatopsis japonica',
  'Burkholderia territorii',
  'Acaryochloris marina',
  'Alcanivorax xenomutans',
  'Lactobacillus crustorum',
  'Arcanobacterium haemolyticum',
  'Streptococcus thermophilus',
  'Streptococcus agalactiae',
  'Capnocytophaga cynodegmi',
  'Klebsiella virus Kp2',
  'Petrimonas mucosa',
  'Prochlorococcus phage Syn1',
  'Paraburkholderia caribensis',
  'RD114 retrovirus',
  'Mycobacterium dioxanotrophicus',
  'Hartmannibacter diazotrophicus',
  'Halomonas huangheensis',
  'Cronobacter phage CR5',
  'Propionibacterium virus Attacne',
  'Natrinema pellirubrum',
  'Bordetella trematum',
  'Photorhabdus temperata',
  'Corynebacterium phage BFK20',
  'Bifidobacterium pseudocatenulatum',
  'Pandoraea vervacti',
  'Clostridium cellulovorans',
  'Variibacter gotjawalensis',
  'Enterococcus thailandicus',
  'Propionibacterium phage PHL301',
  'Corynebacterium maris',
  'Beijerinckia indica',
  'Propionibacterium phage PHL070',
  'Bacillus coagulans',
  'Lactococcus raffinolactis',
  'Burkholderia thailandensis',
  'Shewanella oneidensis',
  'Mycoplasma hominis',
  'Leishmania major',
  'Pediococcus claussenii',
  'Human gammaherpesvirus 4',
  'Bacillus licheniformis',
  'Vibrio campbellii',
  'Enterobacter kobei',
  'Celeribacter indicus',
  'Pseudomonas alkylphenolica',
  'Thermus brockianus',
  'Legionella pneumophila',
  'Photobacterium damselae',
  'Nocardia brasiliensis',
  'Hamiltonella virus APSE1',
  'Haloferax gibbonsii',
  'Chryseobacterium gallinarum',
  'Lysobacter capsici',
  'Flavobacteriaceae bacterium UJ101',
  'Nocardioides dokdonensis',
  'Hyphomonas neptunium',
  'Serratia marcescens',
  'Pseudomonas protegens',
  '[Haemophilus] ducreyi',
  'Nitrosococcus oceani',
  'Chloracidobacterium thermophilum',
  'Nitrosospira briensis',
  'Advenella kashmirensis',
  'Candida dubliniensis',
  'Flavivirga eckloniae',
  'Staphylococcus xylosus',
  'Streptomyces rubrolavendulae',
  'Burkholderia contaminans',
  'Shimwellia blattae',
  'Escherichia coli',
  'Citrobacter braakii',
  'Nitrosococcus watsonii',
  'Streptococcus pneumoniae',
  'Xanthomonas perforans',
  'Streptomyces autolyticus',
  'Streptomyces hygroscopicus',
  'Tenacibaculum dicentrarchi',
  'Acinetobacter oleivorans',
  'Brochothrix phage BL3',
  'Taylorella asinigenitalis',
  'Oscillatoria acuminata',
  'Cellvibrio japonicus',
  'Sulfurospirillum cavolei',
  'Rothia mucilaginosa',
  'Tenacibaculum maritimum',
  'Idiomarina loihiensis',
  'Pseudomonas rhizosphaerae',
  'Candidatus Babela massiliensis',
  'Altererythrobacter namhicola',
  'Gottschalkia acidurici',
  'Xenorhabdus doucetiae',
  'Paenibacillus sabinae',
  'Campylobacter jejuni',
  'Fermentimonas caenicola',
  'Clavibacter sepedonicus',
  'Trichormus azollae',
  'Yersinia similis',
  'Thermomicrobium roseum',
  'Clostridium scatologenes',
  'Thauera humireducens',
  'Cutibacterium granulosum',
  'Erwinia tasmaniensis',
  'Streptococcus mutans',
  'Blastochloris viridis',
  'Psychrobacter alimentarius',
  'Kosakonia cowanii',
  'Polynucleobacter necessarius',
  'Saccharomonospora viridis',
  'Basilea psittacipulmonis',
  'Salimicrobium jeotgali',
  'Reinekea forsetii',
  'Corynebacterium singulare',
  'Porphyromonas gingivalis',
  'Azotobacter vinelandii',
  'Klebsiella virus 1513',
  'Methylomonas denitrificans',
  'Cellulosilyticum lentocellum',
  'Halomicronema hongdechloris',
  'Cyanobium gracile',
  'Comamonadaceae bacterium A1',
  'Pandoraea apista',
  'Staphylococcus hominis',
  'Sphingobacterium mizutaii',
  'Natronococcus occultus',
  'Desulfotomaculum ruminis',
  'Staphylococcus hyicus',
  'Kozakia baliensis',
  'Bacillus pseudofirmus',
  'Klebsiella virus Sushi',
  'Gemmatimonas phototrophica',
  'Kosakonia oryzae',
  'Staphylococcus simulans',
  'Tropheryma whipplei',
  'Propionibacterium phage PHL025',
  'Paraburkholderia phenoliruptrix',
  'Herbaspirillum hiltneri',
  'Bifidobacterium coryneforme',
  'Bifidobacterium dentium',
  'Cyanobacterium aponinum',
  'Lactobacillus phage LfeInf',
  'Ruminococcaceae bacterium CPB6',
  'Desulfovibrio desulfuricans',
  'Edwardsiella phage PEi20',
  'Acidiphilium cryptum',
  'Bacteroides helcogenes',
  'Methanosarcina siciliae',
  'Vibrio tritonius',
  'Pasteurella multocida',
  'Acidipropionibacterium acidipropionici',
  'Spirosoma rigui',
  'Janthinobacterium svalbardensis',
  'Geobacillus stearothermophilus',
  'Corynebacterium testudinoris',
  'Geobacillus thermodenitrificans',
  'Serratia ficaria',
  'Pseudalatia unipuncta granulovirus',
  'Rickettsia amblyommatis',
  'Asaia bogorensis',
  'Mycobacterium colombiense',
  'Loktanella vestfoldensis',
  'Mycobacterium vaccae',
  'Thiobacillus denitrificans',
  'Synechococcus phage S-SM2',
  'Weeksella virosa',
  'Syntrophobotulus glycolicus',
  'Corynebacterium argentoratense',
  'Streptomyces puniciscabiei',
  'Pseudomonas resinovorans',
  'Magnetospirillum gryphiswaldense',
  'Acinetobacter bereziniae',
  'Desulfotomaculum reducens',
  'Halomonas hydrothermalis',
  'Cronobacter sakazakii',
  'Corynebacterium epidermidicanis',
  'Lactobacillus paracasei',
  'Snodgrassella alvi',
  'Helicobacter bilis',
  'Halopenitus persicus',
  'Methanobrevibacter millerae',
  'Sphingobium herbicidovorans',
  'Adlercreutzia equolifaciens',
  'Planococcus antarcticus',
  'Planococcus plakortidis',
  'Dickeya zeae',
  'Pseudomonas citronellolis',
  'Oceanobacillus iheyensis',
  'Caulobacter mirabilis',
  'Lactobacillus amylovorus',
  'Micavibrio aeruginosavorus',
  'Belliella baltica',
  'Catenulispora acidiphila',
  'Tobacco vein clearing virus',
  'Candidatus Sodalis pierantonius',
  'Clostridium formicaceticum',
  'Geobacter pickeringii',
  'Macrococcus caseolyticus',
  'Rubrivivax gelatinosus',
  'Bacteroides vulgatus',
  'Anoxybacillus gonensis',
  'Pseudomonas phage 201phi2-1',
  'Mycobacterium rhodesiae',
  'Sinorhizobium americanum',
  'Burkholderia vietnamiensis',
  'Bacillus muralis',
  'Erwinia phage vB EamM EarlPhillipIV',
  'Spiroplasma floricola',
  'Lacunisphaera limnophila',
  'Nitrosomonas communis',
  'Staphylococcus piscifermentans',
  'Lactococcus virus CB14',
  'Cytophaga hutchinsonii',
  'Cellulophaga lytica',
  'Beutenbergia cavernae',
  'Wenyingzhuangia fucanilytica',
  'Shewanella baltica',
  'Geobacter metallireducens',
  'Porcine type-C oncovirus',
  'Alicyclobacillus acidocaldarius',
  'Burkholderiales bacterium GJ-E10',
  'Corynebacterium stationis',
  'Anaeromyxobacter dehalogenans',
  'Hydrogenobacter thermophilus',
  'Pectobacterium polaris',
  'Streptococcus virus DT1',
  'Granulibacter bethesdensis',
  'Streptomyces bingchenggensis',
  'Thermus aquaticus',
  'Aeromonas media',
  'Neisseria sicca',
  'Pediococcus pentosaceus',
  'Streptomyces noursei',
  'Lactococcus virus CB13',
  'Clavibacter nebraskensis',
  'Azoarcus olearius',
  'Staphylococcus phage StB20',
  'Oscillibacter valericigenes',
  'Arcobacter butzleri',
  'Alkaliphilus metalliredigens',
  'Vibrio natriegens',
  'Pseudoalteromonas translucida',
  'Gemmatirosa kalamazoonesis',
  'Plautia stali symbiont',
  'Pseudorhodoplanes sinuspersici',
  'Propionibacterium virus Stormborn',
  'Agrobacterium vitis',
  'Microbacterium aurum',
  'Immundisolibacter cernigliae',
  'Acholeplasma laidlawii',
  'Bacillus endophyticus',
  'Acetoanaerobium sticklandii',
  'Prochlorococcus phage Syn33',
  'Streptomyces alfalfae',
  'Human endogenous retrovirus K',
  'Gordonibacter urolithinfaciens',
  'Azotobacter chroococcum',
  'Capnocytophaga leadbetteri',
  'Lactococcus phage jm2',
  'Lachnoclostridium phocaeense',
  'Jeotgalibaca dankookensis',
  'Lactobacillus phage LfeSau',
  'Photobacterium profundum',
  'Alteromonas macleodii',
  'Human betaherpesvirus 7',
  'Corynebacterium resistens',
  'Sphingopyxis macrogoltabida',
  'Serratia rubidaea',
  'Pseudomonas cichorii',
  'Prevotella melaninogenica',
  'Kushneria konosiri',
  'Lactobacillus parabuchneri',
  'Edwardsiella ictaluri',
  'Clostridium botulinum',
  'Leisingera methylohalidivorans',
  'Lactobacillus acetotolerans',
  'Caulobacter segnis',
  'Desulfomicrobium baculatum',
  'Halanaeroarchaeum sulfurireducens',
  'Elizabethkingia anophelis',
  'Marinobacter salarius',
  'Thermomonospora curvata',
  'Flavobacterium gilvum',
  'Altererythrobacter epoxidivorans',
  'Siansivirga zeaxanthinifaciens',
  'Marinobacter adhaerens',
  'Xanthomonas hortorum',
  'Citrobacter freundii',
  'Erythrobacter atlanticus',
  'Lactobacillus zymae',
  'Salinispora arenicola',
  'Filimonas lacunae',
  'Pseudomonas amygdali',
  '[Pseudomonas] mesoacidophila',
  'Rickettsia australis',
  'Pluralibacter gergoviae',
  'Herminiimonas arsenicoxydans',
  'Bacteriophage APSE-2',
  'Cardinium endosymbiont of Encarsia pergandiella',
  'Bacteroides ovatus',
  'Streptococcus uberis',
  'Candidatus Nanopelagicus abundans',
  'Legionella fallonii',
  'Polymorphum gilvum',
  'Shigella flexneri',
  'Acetobacter persici',
  'Bdellovibrio exovorus',
  'Eggerthella lenta',
  '[Clostridium] bolteae',
  'Clostridium estertheticum',
  'Defluviimonas alba',
  'Leuconostoc garlicum',
  'Yersinia rohdei',
  'Marinobacter salinus',
  'Halorhabdus utahensis',
  'Streptomyces griseochromogenes',
  'Pandoraea faecigallinarum',
  'Actinosynnema mirum',
  'Prevotella denticola',
  'Xanthomonas fragariae',
  'Corynebacterium deserti',
  'Jonesia denitrificans',
  'Paraburkholderia phytofirmans',
  'Lactobacillus brevis',
  'Photorhabdus asymbiotica',
  'Acinetobacter equi',
  'Ketobacter alkanivorans',
  'Aerococcus urinaehominis',
  'Yersinia aleksiciae',
  'Myxococcus macrosporus',
  'Xenorhabdus nematophila',
  '[Eubacterium] hallii',
  'Acidaminococcus fermentans',
  'Flavobacteriaceae bacterium 3519-10',
  'Rhodoferax ferrireducens',
  'Ndongobacter massiliensis',
  'Halobacillus mangrovi',
  'Candidatus Koribacter versatilis',
  'Desulfotomaculum nigrificans',
  'Chondromyces crocatus',
  'Streptococcus lutetiensis',
  'Enterobacter cloacae',
  'Tessaracoccus aquimaris',
  'Natronomonas pharaonis',
  'Weissella cibaria',
  'Erwinia amylovora',
  'Luteipulveratus mongoliensis',
  'Meiothermus silvanus',
  'Erwinia phage vB EamM Caitlin',
  'Rhodocyclaceae bacterium',
  'Acinetobacter phage vB AbaS TRS1',
  'Paraburkholderia sprentiae',
  'Propionibacterium phage PHL030',
  'Thiobacimonas profunda',
  'Nitrosomonas europaea',
  'Mesorhizobium opportunistum',
  'Niastella koreensis',
  'Bordetella bronchialis',
  'Desulfatibacillum alkenivorans',
  'Glutamicibacter arilaitensis',
  'Verminephrobacter eiseniae',
  'Chryseobacterium taklimakanense',
  'Campylobacter gracilis',
  'Brevibacterium linens',
  'Mobiluncus curtisii',
  'Rhizobium etli',
  'Candidatus Mikella endobia',
  'Bacillus virus Grass',
  'Methylobacterium zatmanii',
  'Bifidobacterium bifidum',
  '[Eubacterium] rectale',
  'Microbacterium hominis',
  'Spiroplasma taiwanense',
  'Alcaligenes faecalis',
  'Bifidobacterium longum',
  'Staphylococcus epidermidis',
  'Chelatococcus daeguensis',
  'Fusobacterium nucleatum',
  'Flavobacterium crassostreae',
  'Solibacillus silvestris',
  'Gallionella capsiferriformans',
  'Parvibaculum lavamentivorans',
  'Rhodococcus jostii',
  'Erysipelotrichaceae bacterium I46',
  'Haloferax volcanii',
  'Corynebacterium marinum',
  'Deinococcus radiodurans',
  'Labrenzia aggregata',
  'Rhodobaca barguzinensis',
  'Octadecabacter antarcticus',
  'Methylocystis bryophila',
  'Methanosarcina lacustris',
  'Bifidobacterium kashiwanohense',
  'Streptococcus phage PH15',
  'Paracoccus yeei',
  'Rothia dentocariosa',
  'Brochothrix thermosphacta',
  'Candidatus Symbiobacter mobilis',
  'Lactobacillus ruminis',
  'Planococcus kocurii',
  'Ferrimonas balearica',
  'Clostridium pasteurianum',
  'Kluyvera intermedia',
  'Halomonas beimenensis',
  'Betaproteobacteria bacterium GR16-43',
  'Paenibacillus ihbetae',
  'Chlorobaculum parvum',
  'Corynebacterium kutscheri',
  'Mesorhizobium loti',
  'Lactobacillus johnsonii',
  'Mycobacterium smegmatis',
  'Dinoroseobacter shibae',
  'Cellulosimicrobium cellulans',
  'Variovorax boronicumulans',
  'Staphylococcus lutrae',
  'Oscillatoria nigro-viridis',
  'Rhodococcus ruber',
  'Gemmatimonas aurantiaca',
  'Corynebacterium striatum',
  'Staphylococcus condimenti',
  'Propionibacterium phage PHL055',
  'Paenibacillus donghaensis',
  'Thermoanaerobacter wiegelii',
  'Lactococcus virus Sl4',
  'Frankia alni',
  'Lactococcus phage 949',
  'Pseudoalteromonas issachenkonii',
  'Flavobacterium indicum',
  'Microvirga ossetica',
  'Yersinia ruckeri',
  'Pimelobacter simplex',
  'Acidaminococcus intestini',
  'Veillonella parvula',
  'Cellulomonas gilvus',
  'Clostridium perfringens',
  'Saccharopolyspora erythraea',
  'Gammapapillomavirus 9',
  'Propionibacterium freudenreichii',
  'Haemophilus pittmaniae',
  'Aneurinibacillus soli',
  'Geosporobacter ferrireducens',
  'Mageeibacillus indolicus',
  'Shewanella japonica',
  'Mythimna unipuncta granulovirus',
  'Altererythrobacter atlanticus',
  'Methanococcus voltae',
  'Propionibacterium phage Enoki',
  'Trichormus variabilis',
  'Nocardia cyriacigeorgica',
  'Steroidobacter denitrificans',
  'Leuconostoc gelidum',
  'Shewanella loihica',
  'Morganella morganii',
  'Dechloromonas aromatica',
  'Dichelobacter nodosus',
  'Ketogulonicigenium robustum',
  'Pseudomonas savastanoi',
  'Pseudomonas entomophila',
  'gamma proteobacterium HdN1',
  'Celeribacter marinus',
  'Tistrella mobilis',
  'Natronomonas moolapensis',
  'Halioglobus pacificus',
  'Sphingomonas hengshuiensis',
  'Ralstonia mannitolilytica',
  'Exiguobacterium sibiricum',
  'Deinococcus geothermalis',
  'Halorubrum trapanicum',
  'Lacinutrix venerupis',
  'Human polyomavirus 5',
  'Providencia rettgeri',
  'Myxococcus xanthus',
  'Variovorax paradoxus',
  'Klebsiella pneumoniae',
  'Mycobacterium thermoresistibile',
  'Aeribacillus pallidus',
  'Sinorhizobium medicae',
  'Leuconostoc mesenteroides',
  'Micrococcus luteus',
  'Cupriavidus metallidurans',
  'Paenibacillus peoriae',
  'Faecalibaculum rodentium',
  'Lelliottia amnigena',
  'Corynebacterium aurimucosum',
  'Tolumonas auensis',
  'Thioclava nitratireducens',
  'Methyloceanibacter caenitepidi',
  'Caulobacteraceae bacterium OTSz A 272',
  'Thioalkalivibrio nitratireducens',
  'Methylosinus trichosporium',
  'Cedecea neteri',
  'Corallococcus coralloides',
  'Enterococcus phage IME-EFm1',
  'Erwinia phage vB EamM Kwan',
  'Pseudoalteromonas arctica',
  'Pseudonocardia dioxanivorans',
  'Staphylococcus schleiferi',
  '[Clostridium] saccharolyticum',
  'Burkholderia lata',
  'Pasteurella dagmatis',
  'Desulfosporosinus acidiphilus',
  'Desulfovibrio magneticus',
  'Pseudomonas phage PaBG',
  'Aerococcus viridans',
  'Betapapillomavirus 1',
  'Capnocytophaga canimorsus',
  'Candidatus Accumulibacter phosphatis',
  'Paracoccus contaminans',
  'Capnocytophaga sputigena',
  'Bifidobacterium choerinum',
  'Pelagibaca abyssi',
  'Aequorivita sublithincola',
  'Candidatus Methylopumilus turicensis',
  'Acinetobacter phage YMC13/03/R2096',
  'Frateuria aurantia',
  'Rhodococcus rhodochrous',
  'Pseudoalteromonas tunicata',
  'Lawsonella clevelandensis',
  'Vagococcus teuberi',
  'Bacillus subtilis',
  'Rhodospirillum rubrum',
  'Burkholderia plantarii',
  'Methanosarcina thermophila',
  'Bacillus aryabhattai',
  'Rhodobacter capsulatus',
  'Planctopirus limnophila',
  'cyanobacterium endosymbiont of Epithemia turgida',
  'Geodermatophilus obscurus',
  'Lactobacillus heilongjiangensis',
  'Dokdonella koreensis',
  'Mesorhizobium ciceri',
  'Clostridium carboxidivorans',
  'Neisseria zoodegmatis',
  'Mycoplasma cynos',
  'Histophilus somni',
  'Rhodococcus pyridinivorans',
  'Riemerella anatipestifer',
  'Chromatiaceae bacterium 2141T.STBD.0c.01a',
  'Nocardia nova',
  'Erwinia phage vB EamM-Y2',
  'Archangium gephyra',
  'Shewanella amazonensis',
  'Capnocytophaga ochracea',
  'Sinorhizobium fredii',
  'Singulisphaera acidiphila',
  'Parasaccharibacter apium',
  'Clavibacter michiganensis',
  'Pseudodesulfovibrio aespoeensis',
  'Eremothecium gossypii',
  'Rhodococcus aetherivorans',
  'Aerococcus urinaeequi',
  'Parachlamydia acanthamoebae',
  'Gammapapillomavirus 7',
  'Streptomyces reticuli',
  'Mycobacterium terrae',
  'Acidobacterium capsulatum',
  'Pseudomonas frederiksbergensis',
  'Rubrobacter radiotolerans',
  'Delftia tsuruhatensis',
  'Martelella endophytica',
  'Rhodoferax antarcticus',
  'Bacillus cohnii',
  'Klebsiella virus KP34',
  'Pseudomonas viridiflava',
  'Pseudomonas fragi',
  'Staphylococcus saprophyticus',
  'Scardovia inopinata',
  'Pseudomonas balearica',
  'Planococcus maritimus',
  'Roseateles depolymerans',
  'Corynebacterium camporealensis',
  'Tessaracoccus flavus',
  'Corynebacterium crudilactis',
  'Mannheimia varigena',
  'Clostridium aceticum',
  'Pseudomonas cerasi',
  'Lactobacillus plantarum',
  'Comamonadaceae bacterium B1',
  'Elizabethkingia miricola',
  'Jeotgalibacillus malaysiensis',
  'Cutibacterium acnes',
  'Phaeobacter porticola',
  'Streptococcus equi',
  'Neisseria elongata',
  'Vibrio fluvialis',
  'Enterobacter asburiae',
  'Aggregatibacter aphrophilus',
  'Fibrobacter succinogenes',
  'Nostoc flagelliforme',
  'Propionibacterium phage PAC1',
  'Streptococcus dysgalactiae',
  'Filifactor alocis',
  'Actinoalloteichus hymeniacidonis',
  'Helicobacter pylori',
  'Geobacillus subterraneus',
  'Propionibacterium virus PHL071N05',
  'Klebsiella quasivariicola',
  'Streptomyces scabiei',
  'Corynebacterium lactis',
  'Mycobacterium tuberculosis',
  'Clavibacter insidiosus',
  'Campylobacter coli',
  'Gallibacterium anatis',
  'Roseomonas gilardii',
  'Candidatus Portiera aleyrodidarum',
  'Sulfuricaulis limicola',
  'Ureaplasma urealyticum',
  'Selenomonas sputigena',
  'Proteus vulgaris',
  'Pseudomonas poae',
  'Methylobacterium phyllosphaerae',
  'Natronobacterium gregoryi',
  'Methanocorpusculum labreanum',
  'Bacillus cereus',
  'Bacillus horikoshii',
  'Thalassolituus oleivorans',
  'Slackia heliotrinireducens',
  'Xenorhabdus poinarii',
  'Oceanithermus profundus',
  'Mariprofundus aestuarium',
  'Altererythrobacter mangrovi',
  'Escherichia phage phAPEC8',
  'Porphyromonas asaccharolytica',
  'Opitutus terrae',
  'Bacillus pumilus',
  'Propionibacterium phage PHL092',
  'Roseobacter denitrificans',
  'Haliscomenobacter hydrossis',
  'Halorhabdus tiamatea',
  'Sphingobium japonicum',
  'Flavobacterium branchiophilum',
  'Clostridium cochlearium',
  'Staphylococcus virus Sextaec',
  'Thermobacillus composti',
  'Pelagibacterium halotolerans',
  'Paenalcaligenes hominis',
  'Fusobacterium varium',
  'Acetobacterium woodii',
  'Treponema putidum',
  'Escherichia marmotae',
  'Limnochorda pilosa',
  'Sorangium cellulosum',
  'Lactobacillus acidophilus',
  'Croceicoccus naphthovorans',
  'Elizabethkingia meningoseptica',
  'Phenylobacterium zucineum',
  'Bifidobacterium adolescentis',
  'Sulfurospirillum barnesii',
  'Eikenella corrodens',
  'Clavibacter capsici',
  '[Clostridium] cellulolyticum',
  'Sphingobium indicum',
  'Methylotenera mobilis',
  'Corynebacterium humireducens',
  'Yersinia aldovae',
  'Serratia proteamaculans',
  'Raoultella ornithinolytica',
  'Acidovorax ebreus',
  'Nitrosococcus halophilus',
  'Staphylococcus aureus',
  'Burkholderia seminalis',
  'Ketogulonicigenium vulgare',
  'Crinalium epipsammum',
  'Xanthomonas euvesicatoria',
  'Neisseria gonorrhoeae',
  'Enterococcus faecium',
  'Lactococcus garvieae',
  'Agrobacterium rhizogenes',
  'Megasphaera elsdenii',
  'Celeribacter ethanolicus',
  'Haemophilus influenzae',
  'White spot syndrome virus',
  'Pantoea virus Limelight',
  'Thalassospira xiamenensis',
  'Pseudomonas fluorescens',
  'Clostridium saccharoperbutylacetonicum',
  'Myroides profundi',
  'Campylobacter curvus',
  'Halomonas campaniensis',
  'Tatumella citrea',
  'Corynebacterium casei',
  'Cryobacterium arcticum',
  'Lactobacillus fermentum',
  'Desulfotomaculum gibsoniae',
  'Spiroplasma helicoides',
  'Klebsiella virus KLPN1',
  'Streptomyces malaysiensis',
  'Xanthomonas campestris',
  'Shigella virus AG3',
  'Lactobacillus farciminis',
  'Bacteroides cellulosilyticus',
  'Bacillus virus TP21',
  'Salmonella virus SPN3US',
  'Rummeliibacillus stabekisii',
  'Caldilinea aerophila',
  'Nitrospira defluvii',
  'Syntrophobacter fumaroxidans',
  'Pseudohongiella spirulinae',
  'Turneriella parva',
  'Acidiphilium multivorum',
  'Acinetobacter soli',
  'Vibrio breoganii',
  'Clostridium saccharobutylicum',
  'Desulfosporosinus orientis',
  'Neisseriaceae bacterium DSM 100970',
  'Enterobacter virus PG7',
  'Aerococcus christensenii',
  'Shewanella denitrificans',
  'Clostridium sporogenes',
  'Clostridioides difficile',
  'Niabella ginsenosidivorans',
  'Xanthobacter autotrophicus',
  'Bacillus simplex',
  'Psychromonas ingrahamii',
  'Pseudomonas trivialis',
  'Paludisphaera borealis',
  'Ensifer sojae',
  'Klebsiella michiganensis',
  'uncultured crAssphage',
  'Rathayibacter toxicus',
  'Methylovulum psychrotolerans',
  'Streptomyces formicae',
  'Niveispirillum cyanobacteriorum',
  'Verrucomicrobia bacterium IMCC26134',
  'Pseudoxanthomonas spadix',
  'Haloarcula taiwanensis',
  'Lactobacillus reuteri',
  'Planococcus halocryophilus',
  'Acinetobacter larvae',
  'Sugiyamaella lignohabitans',
  'Sphingomonas koreensis',
  'Pelosinus fermentans',
  'Coprothermobacter proteolyticus',
  'Gordonia terrae',
  'Methanothrix soehngenii',
  'Psychrobacter urativorans',
  'Actinoalloteichus hoggarensis',
  'Bacillus megaterium',
  'Gilliamella apicola',
  'Pseudomonas knackmussii',
  'Erwinia billingiae',
  'Natrialbaceae archaeon JW/NM-HA 15',
  'Allochromatium vinosum',
  'Ezakiella massiliensis',
  'Stanieria cyanosphaera',
  'Aeromonas salmonicida',
  'Streptococcus suis',
  'Rufibacter tibetensis',
  'Mycobacterium stephanolepidis',
  'Streptococcus phage EJ-1',
  'Tepidanaerobacter acetatoxydans',
  'Streptomyces fulvissimus',
  'Woeseia oceani',
  'Prevotella jejuni',
  'Corynebacterium glaucum',
  'Hymenobacter sedentarius',
  'Bacillus weihaiensis',
  'Deinococcus swuensis',
  'Pseudomonas cremoricolorata',
  'Bacillus toyonensis',
  'Pantoea ananatis',
  'Wenzhouxiangella marina',
  'Gordonia phage GTE6',
  'Sphingobium cloacae',
  'Terriglobus saanensis',
  'Fuerstia marisgermanicae',
  'Psychrobacter cryohalolentis',
  'Ensifer adhaerens',
  'Azospirillum humicireducens',
  'Paenibacillus stellifer',
  'Enterococcus durans',
  'Cupriavidus gilardii',
  'Streptomyces lydicus',
  '[Clostridium] clariflavum',
  'Aeromonas caviae',
  'Dactylococcopsis salina',
  'Desulfovibrio fairfieldensis',
  'Streptococcus anginosus',
  'Bacillus krulwichiae',
  'Deinococcus soli Cha et al. 2016',
  'Sphingomonas taxi',
  'Barnesiella viscericola',
  'Nocardia terpenica',
  'Bordetella petrii',
  'Streptomyces xinghaiensis',
  'Haloterrigena daqingensis',
  'Haematospirillum jordaniae',
  'Streptococcus virus phiAbc2',
  'Burkholderia stabilis',
  'alpha proteobacterium HIMB59',
  'Lactobacillus backii',
  'Myxococcus stipitatus',
  'Pedobacter steynii',
  'Acinetobacter lactucae',
  'Mycobacterium yongonense',
  'Vibrio parahaemolyticus',
  'Spodoptera frugiperda granulovirus',
  'Hahella chejuensis',
  'Acidihalobacter prosperus',
  'Gardnerella vaginalis',
  'Nocardiopsis gilva',
  'Sporosarcina psychrophila',
  'Rickettsia rhipicephali',
  'Human alphaherpesvirus 3',
  'Prochlorococcus marinus',
  'Sphingobacteriaceae bacterium GW460-11-11-14-LB5',
  'Parvularcula bermudensis',
  'Propionibacterium phage PHL116',
  'Xylella fastidiosa',
  'Altererythrobacter marensis',
  'Rhodothermaceae bacterium RA',
  'Rhodoferax saidenbachensis',
  'Vitreoscilla filiformis',
  'Brevundimonas subvibrioides',
  'Pandoravirus dulcis',
  'Nitrosospira lacus',
  'Sphingopyxis granuli',
  'Mycobacterium chubuense',
  'Klebsiella aerogenes',
  'Bordetella avium',
  'Cellulophaga baltica',
  'Tannerella forsythia',
  'Clostridium novyi',
  'Lactobacillus agilis',
  'Dialister pneumosintes',
  'Pleurocapsa minor',
  'Streptomyces albulus',
  'Burkholderia stagnalis',
  'Desulfitobacterium hafniense',
  'Sedimenticola thiotaurini',
  'Butyrivibrio hungatei',
  'Methanobrevibacter smithii',
  'Streptomyces pristinaespiralis',
  'Synechococcus phage ACG-2014d',
  'Vibrio tapetis',
  'Halotalea alkalilenta',
  'Oceanicoccus sagamiensis',
  'Cloacibacillus porcorum',
  'Syntrophomonas wolfei',
  'Paraburkholderia xenovorans',
  'Pseudomonas antarctica',
  'Lysinibacillus varians',
  'Parvimonas micra',
  'Mannheimia haemolytica',
  'Kineococcus radiotolerans',
  'Actinoplanes friuliensis',
  'Streptomyces violaceoruber',
  'Niabella soli',
  'Chromobacterium violaceum',
  'Sphingomonas melonis',
  'Roseburia hominis',
  'Acinetobacter baumannii',
  'Paenibacillus beijingensis',
  'Thermobispora bispora',
  'Pelobacter acetylenicus',
  'Human mastadenovirus E',
  'Chitinophaga pinensis',
  'Fusobacterium hwasookii',
  'Laribacter hongkongensis',
  'Corynebacterium ureicelerivorans',
  'Geobacillus thermoleovorans',
  'Aliivibrio fischeri',
  'Brucella vulpis',
  'Bacillus oceanisediminis',
  'Xanthomonas citri',
  'Lacimicrobium alkaliphilum',
  'Propionibacterium virus P105',
  'Rhodococcus hoagii',
  'Paraburkholderia fungorum',
  'halophilic archaeon DL31',
  'Rickettsia endosymbiont of Bemisia tabaci',
  'Paeniclostridium sordellii',
  'Pseudomonas syringae',
  'Citrobacter amalonaticus',
  'Weissella koreensis',
  'Pseudomonas veronii',
  'Helicobacter cinaedi',
  'Streptomyces venezuelae',
  'Alcanivorax pacificus',
  'Rhizobium gallicum',
  'Faecalibacterium prausnitzii',
  'Ureaplasma parvum',
  'Methylocella silvestris',
  'Arcobacter nitrofigilis',
  'Sphingopyxis alaskensis',
  'Thermobifida fusca',
  'Collimonas pratensis',
  'Bacteroidales bacterium CF',
  'Ilumatobacter coccineus',
  'Actinobacillus pleuropneumoniae',
  'Streptococcus iniae',
  'Lactobacillus phage Lv-1',
  'Actinoplanes missouriensis',
  'Polaribacter reichenbachii',
  'Bacteroides fragilis',
  'Candidatus Sulcia muelleri',
  'Asticcacaulis excentricus',
  'Komagataeibacter xylinus',
  'Microcella alkaliphila',
  'Methylococcus capsulatus',
  'Roseobacter litoralis',
  'Corynebacterium halotolerans',
  'Staphylococcus capitis',
  'Staphylococcus equorum',
  'Sporosarcina ureae',
  'Leuconostoc lactis',
  'Aerococcus sanguinicola',
  'Bacillus kochii',
  'Anabaena cylindrica',
  'Bifidobacterium pseudolongum',
  'Pseudomonas putida',
  'Spiribacter curvatus',
  'Gordonia bronchialis',
  'Propionibacterium phage PHL095',
  'Candidatus Pelagibacter ubique',
  'Pirellula staleyi',
  'Cobetia marina',
  'Clostridium kluyveri',
  'Streptomyces niveus',
  'Methylovorus glucosotrophus',
  'Erwinia phage ENT90',
  'Mycobacterium gilvum',
  'Vibrio alginolyticus',
  'Pseudomonas chlororaphis',
  'Actinobacteria bacterium IMCC26256',
  'Paenibacillus riograndensis',
  'Pseudomonas azotoformans',
  'Dickeya dadantii',
  'Pseudomonas phage Pf-10',
  'Muricauda ruestringensis',
  'Actinotignum schaalii',
  'Orrella dioscoreae',
  'Virgibacillus necropolis',
  'Cycloclasticus zancles',
  'Defluviitoga tunisiensis',
  'Neoasaia chiangmaiensis',
  'Staphylococcus pseudintermedius',
  'Deinococcus peraridilitoris',
  'Auricoccus indicus',
  '[Mannheimia] succiniciproducens',
  'Herbaspirillum frisingense',
  'Rhodothermus marinus',
  'Devriesea agamarum',
  'Streptosporangium roseum',
  'Bacillus atrophaeus',
  'Chryseobacterium indologenes',
  'Alicycliphilus denitrificans',
  'Lactobacillus helveticus',
  'Isoptericola variabilis',
  'Bosea vaviloviae',
  'Aliivibrio wodanis',
  'Corynebacterium diphtheriae',
  'Streptococcus pseudopneumoniae',
  'Rhodococcus fascians',
  'Candidatus Tremblaya princeps',
  'Actinomyces oris',
  'Corynebacterium imitans',
  'Lactobacillus casei',
  'Streptomyces pluripotens',
  'Citrobacter werkmanii',
  'Terribacillus aidingensis',
  'Novosphingobium pentaromativorans',
  'Verrucomicrobia bacterium',
  'Xenorhabdus hominickii',
  'Erythrobacter litoralis',
  'Acidithiobacillus ferrooxidans',
  'Kushneria marisflavi',
  'Cystobacter fuscus',
  'Plesiomonas shigelloides',
  'Xanthomonas fuscans',
  'Kluyveromyces lactis',
  'Flavobacterium johnsoniae',
  'Streptomyces vietnamensis',
  'Streptococcus constellatus',
  'Flavobacterium commune',
  'Aurantimicrobium minutum',
  'Halobacterium salinarum',
  'Desulfococcus oleovorans',
  'Gluconobacter oxydans',
  'Meiothermus ruber',
  'Rhizobium leguminosarum',
  'Pontibacter korlensis',
  'Pararhodospirillum photometricum',
  'Campylobacter hominis',
  'Enterococcus hirae',
  'Clostridium argentinense',
  'Providencia alcalifaciens',
  'Sphingopyxis fribergensis',
  'Pseudopedobacter saltans',
  'Streptomyces albus',
  'Synechococcus phage S-SKS1',
  'Enterobacter xiangfangensis',
  'Collinsella aerofaciens',
  'Brochothrix phage A9',
  'Thermus oshimai',
  'Hoyosella subflava',
  'Burkholderia multivorans',
  'Xanthomonas gardneri',
  'Ochrobactrum anthropi',
  'Candidatus Saccharibacteria oral taxon TM7x',
  'Citrobacter koseri',
  'Halomonas virus HAP1',
  'Bifidobacterium asteroides',
  'Propionibacterium virus Pirate',
  'Pectobacterium parmentieri',
  'Alcanivorax dieselolei',
  'Glaesserella parasuis',
  'Paenibacillus xylanexedens',
  'Saccharophagus degradans',
  'Spiroplasma monobiae',
  'Ralstonia pickettii',
  'Dermacoccus nishinomiyaensis',
  'Dickeya solani',
  'Bifidobacterium indicum',
  'Castellaniella defragrans',
  'Staphylococcus warneri',
  'Anoxybacillus flavithermus',
  'Propionibacterium phage PHL067M10',
  'Lutibacter profundi',
  'Burkholderia cepacia',
  'Kosakonia radicincitans',
  'Halioglobus japonicus',
  'Fluviicola taffensis',
  'Streptomyces ambofaciens',
  'Nitrospira japonica',
  'Lactobacillus paracollinoides',
  'Pediococcus damnosus',
  'Bacteroides dorei',
  'Massilia putida',
  'Propionibacterium virus P100A',
  'Heliobacterium modesticaldum',
  'Chromohalobacter salexigens',
  'Staphylococcus pettenkoferi',
  'Kibdelosporangium phytohabitans',
  'Actinomyces radicidentis',
  'Streptomyces pratensis',
  'Dickeya dianthicola',
  'Lactobacillus mucosae',
  'Streptococcus himalayensis',
  "Enterobacter cloacae complex 'Hoffmann cluster III'",
  'Bacillus safensis',
  'Micromonas pusilla virus 12T',
  'Acholeplasma brassicae',
  'Streptomyces globisporus',
  'Streptomyces cyaneogriseus',
  'Yersinia pestis',
  'Herminiimonas arsenitoxidans',
  'Erysipelothrix larvae',
  'Kutzneria albida',
  'Staphylococcus phage StB27',
  'Enterobacteriaceae bacterium strain FGI 57',
  'Neorhizobium galegae',
  'Lactobacillus alimentarius',
  'Staphylococcus virus 71',
  'Komagataeibacter nataicola',
  'Cronobacter virus GAP31',
  'Cupriavidus pinatubonensis',
  'Nitrosospira multiformis',
  'Roseiflexus castenholzii',
  'Burkholderia pseudomultivorans',
  'Cellulomonas flavigena',
  'Aminobacter aminovorans',
  'Brucella phage BiPBO1',
  'Nocardia soli',
  'Odoribacter splanchnicus',
  'Synechococcus phage ACG-2014i',
  'Streptococcus parauberis',
  'Trichodesmium erythraeum',
  'Dehalobacterium formicoaceticum',
  'Pseudomonas stutzeri',
  'Chryseobacterium glaciei',
  'Neomicrococcus aestuarii',
  'Rhizorhabdus dicambivorans',
  'Conexibacter woesei',
  'Staphylococcus pasteuri',
  'Lysinibacillus fusiformis',
  'Pseudomonas monteilii',
  'Bernardetia litoralis',
  'Vibrio scophthalmi',
  'Propionibacterium phage PHL132',
  'Spiroplasma culicicola',
  'Listeria ivanovii',
  'Pseudomonas phage PS-1',
  'Planococcus rifietoensis',
  'Nostoc punctiforme',
  'Acinetobacter nosocomialis',
  'Erwinia pyrifoliae',
  'Staphylococcus phage phiRS7',
  'Streptomyces sampsonii',
  'Lactobacillus delbrueckii',
  'Moraxella bovoculi',
  'Melittangium boletus',
  'Bifidobacterium actinocoloniiforme',
  'Tsukamurella phage TPA4',
  'Salmonella phage SKML-39',
  'Vibrio anguillarum',
  'Tessaracoccus flavescens',
  'Pseudomonas oryzihabitans',
  'Human mastadenovirus B',
  'Erwinia phage vB EamM Phobos',
  'Bacillus mycoides',
  'Flavonifractor plautii',
  'Murdochiella vaginalis',
  'Burkholderia oklahomensis',
  'Chromobacterium vaccinii',
  'Methylotenera versatilis',
  'Kitasatospora aureofaciens',
  '[Eubacterium] eligens',
  'Nostocales cyanobacterium HT-58-2',
  'Microbulbifer agarilyticus',
  'Acinetobacter calcoaceticus',
  'Nocardia seriolae',
  'Desulfotomaculum acetoxidans',
  'Staphylococcus virus IPLA7',
  'Stenotrophomonas maltophilia',
  'Pseudomonas mendocina',
  'Treponema succinifaciens',
  'Rhizobacter gummiphilus',
  'Sphingobium baderi',
  'Achromobacter denitrificans',
  'Spiroplasma apis',
  'Pectobacterium carotovorum',
  'Pseudomonas phage VCM',
  'Rathayibacter tritici',
  'Paracoccus aminophilus',
  'Sphingomonas wittichii',
  'Alteromonas naphthalenivorans',
  'Spiroplasma citri',
  'Blautia hansenii',
  'Staphylococcus simiae',
  'Ruminiclostridium thermocellum',
  'Chloroflexus aggregans',
  'Erythrobacter gangjinensis',
  'Staphylococcus argenteus',
  'Cupriavidus basilensis',
  'Caulobacter vibrioides',
  'Pectobacterium atrosepticum',
  'Bacillus cellulosilyticus',
  'Xanthomonas sacchari',
  'Bradyrhizobium diazoefficiens',
  'Gloeobacter violaceus',
  'Klebsiella phage 0507-KN2-1',
  'Sphingobium yanoikuyae',
  'Acidimicrobium ferrooxidans',
  'Clostridium beijerinckii',
  'Corynebacterium uterequi',
  'Ornithobacterium rhinotracheale',
  'Nitrospirillum amazonense',
  'Staphylococcus phage StB12',
  'Streptomyces pactum',
  'Enterococcus phage EFDG1',
  'Propionibacterium virus PHL113M01',
  'Erwinia gerundensis',
  'Flavisolibacter tropicus',
  'Photobacterium gaetbulicola',
  'Microcystis aeruginosa',
  'Clostridium butyricum',
  'Lactobacillus phage Lc-Nu',
  'Paenibacillus terrae',
  'Stenotrophomonas phage Smp131',
  'Acinetobacter virus AP22',
  'Brevibacillus laterosporus',
  'Pseudoalteromonas espejiana',
  'Enterococcus phage EFC-1',
  'Methylobacterium radiotolerans',
  'Bordetella pseudohinzii',
  'Klebsiella phage vB KpnM KB57',
  'Aerococcus urinae',
  'Azospirillum thiophilum',
  'Leadbetterella byssophila',
  'Mycoplasma hyopneumoniae',
  'Blattabacterium cuenoti',
  'Nakamurella multipartita',
  'Aggregatibacter actinomycetemcomitans',
  'Bifidobacterium breve',
  'Thermaerobacter marianensis',
  'Bacillus clausii',
  'Sulfurifustis variabilis',
  'Mycobacterium marinum',
  'Dyella japonica',
  'Streptomyces glaucescens',
  'Methylobacterium populi',
  'Sulfuritalea hydrogenivorans',
  'Acinetobacter pittii',
  'Alteromonas mediterranea',
  'Blastococcus saxobsidens',
  'Corynebacterium falsenii',
  'Frankia symbiont of Datisca glomerata',
  'Halopiger xanaduensis',
  'Exiguobacterium antarcticum',
  'Gramella flava',
  'Marinobacter hydrocarbonoclasticus',
  'Herbivorax saccincola',
  'Lysobacter antibioticus',
  'Weissella jogaejeotgali',
  'Sodalis praecaptivus',
  'Psychrobacter arcticus',
  'Xanthomonas translucens',
  'Thiomonas intermedia',
  'Paraburkholderia terrae',
  'Burkholderia metallica',
  'Renibacterium salmoninarum',
  'Streptococcus phage PH10',
  'Hyphomicrobium denitrificans',
  'Propionibacterium phage PA1-14',
  'Pseudomonas fulva',
  'Lactobacillus phage phiAQ113',
  'Intrasporangium calvum',
  'Marinobacterium aestuarii',
  'Paenibacillus bovis',
  'Oleispira antarctica',
  'Polaribacter vadi',
  'Sanguibacter keddieii',
  'Propionibacterium virus Pacnes201215',
  'Intestinimonas butyriciproducens',
  'Corynebacterium glyciniphilum',
  'Roseovarius mucosus',
  'Streptococcus halotolerans',
  'secondary endosymbiont of Heteropsylla cubana',
  'Phycisphaera mikurensis',
  'Propionibacterium virus ATCC29399BT',
  'Haloarcula marismortui',
  'Weissella paramesenteroides',
  'Bacillus paralicheniformis',
  'Sphingobium hydrophobicum',
  'Peptoclostridium acidaminophilum',
  'Mycobacterium goodii',
  'Brevundimonas naejangsanensis',
  'Bacillus cytotoxicus',
  'Cellulomonas fimi',
  'Streptococcus virus ALQ132',
  'Azospirillum lipoferum',
  'Veillonella rodentium',
  'Coriobacterium glomerans',
  'Nitrobacter hamburgensis',
  'Campylobacter fetus',
  'Bordetella bronchiseptica',
  'Candidatus Cloacimonas acidaminovorans',
  'Amycolatopsis mediterranei',
  'Lactobacillus amylolyticus',
  'Streptococcus merionis',
  'Hydrogenophaga crassostreae',
  'Candidatus Nitrosopumilus sediminis',
  'Mycobacterium phage MOOREtheMARYer',
  'Propionibacterium phage PHL152',
  'Acetobacter pomorum',
  'Novosphingobium aromaticivorans',
  'Chroococcidiopsis thermalis',
  'Ethanoligenens harbinense',
  'Acinetobacter haemolyticus',
  'Lactobacillus sanfranciscensis',
  'Erwinia phage phiEa2809',
  'Marinithermus hydrothermalis',
  'Isoptericola dokdonensis',
  'Thioalkalivibrio paradoxus',
  'Planktomarina temperata',
  'Fimbriimonas ginsengisoli',
  'Staphylococcus muscae',
  'Sulfuricella denitrificans',
  'Dehalococcoides mccartyi',
  'Hafnia alvei',
  'Bacteroides thetaiotaomicron',
  'Streptomyces chartreusis',
  'Ralstonia solanacearum',
  'Octadecabacter arcticus',
  'Bacillus halodurans',
  'Finegoldia magna',
  'Advenella mimigardefordensis',
  'Halomonas chromatireducens',
  'Amphibacillus xylanus',
  'Edwardsiella tarda',
  'Pantoea agglomerans',
  'Streptococcus virus 7201',
  'Ramlibacter tataouinensis',
  'Corynebacterium callunae',
  'Streptomyces albireticuli',
  'Capnocytophaga stomatis',
  'Haloterrigena turkmenica',
  'Paenibacillus crassostreae',
  'Melissococcus plutonius',
  'Streptomyces coelicolor',
  'Acidithiobacillus ferrivorans',
  'Streptococcus virus Cp1',
  'Halomicrobium mukohataei',
  'Bordetella hinzii',
  'Thermogutta terrifontis',
  'Methylophaga frappieri',
  'Candidatus Planktophila vernalis',
  'Janibacter indicus',
  'Candidatus Solibacter usitatus',
  'Mamestra configurata nucleopolyhedrovirus A',
  'Fictibacillus phosphorivorans',
  'Lactobacillus pentosus',
  'Wolbachia endosymbiont of Drosophila simulans',
  'Vibrio antiquarius',
  'Lactobacillus jensenii',
  'Streptococcus marmotae',
  'Dyella jiangningensis',
  'Microbacterium testaceum',
  'Cnuibacter physcomitrellae',
  'Aeromonas aquatica',
  'Eubacterium limosum',
  'Psychrobacter phage Psymv2',
  'Prevotella intermedia',
  'Collimonas arenae',
  'Lactobacillus salivarius',
  'Methanococcus aeolicus',
  'Mycobacterium immunogenum',
  'Coriobacteriaceae bacterium 68-1-3',
  'Anoxybacillus amylolyticus',
  'Corynebacterium frankenforstense',
  'Enterobacteriaceae bacterium ENNIH2',
  'Streptococcus parasanguinis',
  'Rhodobacteraceae bacterium QY30',
  'Halobacterium hubeiense',
  'Sphingobium chlorophenolicum',
  'Pseudoalteromonas phenolica',
  'Pseudomonas psychrotolerans',
  'Parabacteroides distasonis',
  'Yersinia frederiksenii',
  'Lactobacillus amylophilus',
  'Robiginitalea biformata',
  'Frankia inefficax',
  'Bacillus gibsonii',
  'Lactobacillus curvatus',
  'Clostridium bornimense',
  'Acetobacter pasteurianus',
  'Stigmatella aurantiaca',
  'Dermatophilus congolensis',
  'Kitasatospora setae',
  'Comamonas kerstersii',
  'Nitrosomonas eutropha',
  'Escherichia fergusonii',
  'Streptococcus gordonii',
  'Dickeya fangzhongdai',
  'Cronobacter dublinensis',
  'Tsukamurella paurometabola',
  'Streptomyces lavendulae',
  'Azospirillum brasilense',
  'Desulfococcus multivorans',
  'Streptomyces gilvosporeus',
  'Christensenella massiliensis',
  'Novosphingobium resinovorum',
  'Streptomyces griseus',
  'Rhodanobacter denitrificans',
  'Rickettsia massiliae',
  'Aliivibrio salmonicida',
  'Pantoea alhagi',
  'Shewanella putrefaciens',
  'Akkermansia glycaniphila',
  'Acinetobacter junii',
  'Neisseria lactamica',
  'Wolbachia endosymbiont of Cimex lectularius',
  'Methanobrevibacter ruminantium',
  'Bacillus glycinifermentans',
  'Herbinix luporum',
  'Methanothermobacter wolfeii',
  'Microbacterium paludicola',
  'Rhodococcus opacus',
  'Komagataeibacter europaeus',
  'Zymomonas mobilis',
  'Chania multitudinisentens',
  'Pedobacter cryoconitis',
  'Geobacter anodireducens',
  'Oceanisphaera profunda',
  'Lactococcus piscium',
  'Escherichia virus CC31',
  'Piscirickettsia salmonis',
  'Pectobacterium wasabiae',
  'Pseudoalteromonas nigrifaciens',
  'Desulfovibrio piger',
  'Geobacter sulfurreducens',
  'Bordetella flabilis',
  'Butyrivibrio proteoclasticus',
  'Spiroplasma diminutum',
  'Synechococcus lividus',
  'Streptomyces collinus',
  'Cotesia congregata bracovirus',
  'Pseudoxanthomonas suwonensis',
  'Kocuria flava',
  'Pandoraea norimbergensis',
  'Microbulbifer thermotolerans',
  'Actinomyces pacaensis',
  'Rhodospirillum centenum',
  'Leuconostoc citreum',
  'Halorubrum lacusprofundi',
  'BeAn 58058 virus',
  'Ochrobactrum pseudogrignonense',
  'Mycobacterium abscessus',
  'Gordonibacter massiliensis',
  'Bacillus flexus',
  'Sphingopyxis terrae',
  'Rhodopirellula baltica',
  'Collimonas fungivorans',
  'Pseudopropionibacterium propionicum',
  'Cronobacter malonaticus',
  'Escherichia phage HK639',
  'Ruminococcus albus',
  'Mycobacterium saopaulense',
  'Marinomonas posidonica',
  'Kytococcus sedentarius',
  'Vibrio vulnificus',
  'Acetobacter tropicalis',
  'Salmonella phage 7-11',
  'Caulobacter henricii',
  'Acidothermus cellulolyticus',
  'Spirosoma aerolatum',
  'Salmonella enterica',
  'Corynebacterium ulcerans',
  'Staphylococcus lugdunensis',
  'Bifidobacterium angulatum',
  'Komagataeibacter medellinensis',
  'Paenibacillus swuensis',
  'Campylobacter concisus',
  'Moraxella catarrhalis',
  'Bacteroides caecimuris',
  'Sandaracinus amylolyticus',
  'Streptomyces alboflavus',
  'Mesorhizobium japonicum',
  'Sneathia amnii',
  'Treponema denticola',
  'Diaphorobacter polyhydroxybutyrativorans',
  'Gordonia phthalatica',
  'Dyella thiooxydans',
  'Proteus mirabilis',
  '[Clostridium] thermosuccinogenes',
  'Paracoccus denitrificans',
  'Shewanella marisflavi',
  'Enterobacter cancerogenus',
  'Polynucleobacter asymbioticus',
  'Planococcus donghaensis',
  'endosymbiont of unidentified scaly snail isolate Monju',
  'Pseudomonas brassicacearum',
  'Human polyomavirus 6',
  'Mycobacterium haemophilum',
  'Sebaldella termitidis',
  'Leifsonia xyli',
  'Thioflavicoccus mobilis',
  'Methylobacterium oryzae',
  'Rhodococcus erythropolis',
  'Prevotella dentalis',
  'Corynebacterium variabile',
  'Chlorobaculum limnaeum',
  'Aeromonas schubertii',
  'Nitrobacter winogradskyi',
  'Amycolatopsis methanolica',
  'Xanthomonas phaseoli',
  'Sinomonas atrocyanea',
  'Chryseobacterium camelliae',
  'Bacillus lehensis',
  'Geobacillus lituanicus',
  'Candidatus Nitrosomarinus catalina',
  'Providencia stuartii',
  'Xanthomonas alfalfae',
  'Sideroxydans lithotrophicus',
  'Oblitimonas alkaliphila',
  'Mesorhizobium australicum',
  'Methylophaga nitratireducenticrescens',
  'Moraxella osloensis',
  'Frankia casuarinae',
  'Aeromicrobium erythreum',
  'Pseudomonas pseudoalcaligenes',
  'Staphylococcus virus SEP9',
  'Myxococcus fulvus',
  'Theileria orientalis',
  'Vibrio coralliilyticus',
  'Janthinobacterium agaricidamnosum',
  'Bradyrhizobium japonicum',
  'Lactococcus phage 28201',
  'Human polyomavirus 4',
  'Lysobacter enzymogenes',
  'Bacillus infantis',
  'Brevibacillus formosus',
  'Wigglesworthia glossinidia',
  'Propionibacterium virus Lauchelly',
  'Chitinophagaceae bacterium 13',
  'Burkholderia diffusa',
  'Xenorhabdus bovienii',
  'Streptomyces peucetius',
  'Rhodobacter sphaeroides',
  '[Polyangium] brachysporum',
  'Kiritimatiella glycovorans',
  'Vibrio tubiashii',
  'Halothiobacillus neapolitanus',
  'Geoalkalibacter subterraneus',
  'Methanosarcina mazei',
  'Campylobacter ureolyticus',
  'Staphylococcus succinus',
  'Actinosynnema pretiosum',
  'Lactobacillus gasseri',
  'Corynebacterium phocae',
  'Desulfitobacterium dichloroeliminans',
  'Rickettsia felis',
  'Mycobacterium chimaera',
  'Pseudomonas alcaligenes',
  '[Enterobacter] lignolyticus',
  'Marinobacter similis',
  'Methylibium petroleiphilum',
  'Pantoea rwandensis',
  'Pandoravirus salinus',
  'Halomonas aestuarii',
  'Streptococcus phage SpSL1',
  'Deinococcus actinosclerus',
  'Capnocytophaga haemolytica',
  'Isosphaera pallida',
  'Corynebacterium ammoniagenes',
  'Ochrobactrum pituitosum',
  'Clostridium acetobutylicum',
  'Staphylococcus sciuri',
  'Salinigranum rubrum',
];

export { taxa_suggestions };
